import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Layout from 'src/components/Layout'
import MasonryPosts from 'src/components/shared/MasonryPosts'
import EmailCapture from 'src/components/shared/EmailCapture'
import SEO from 'src/components/shared/SEO'

const Wrapper = styled.section`
  min-height: 80vh;
`

const TagRoute = ({ data }) => {
  const posts = data.allMarkdownRemark.edges.map((edge) => edge.node)
  return (
    <>
      <SEO />
      <Layout>
        <Wrapper>
          <MasonryPosts posts={posts} />
        </Wrapper>
        <EmailCapture />
      </Layout>
    </>
  )
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, draft: { eq: false } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            slug
            draft
            title
            large
            templateKey
            tags
            featuredimage {
              childImageSharp {
                gatsbyImageData(width: 250, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`
